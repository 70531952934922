import { getTestData } from "../modules/test/manageTestData";

function showModal(modalId) {
  const modal = document.getElementById(modalId);
  if (modal) {
    modal.style.display = 'flex';

    modal.addEventListener('click', onOutsideClick);
    document.addEventListener('keydown', onEscPress);
    const buttons = modal.querySelectorAll('button');
    buttons.forEach(button => button.addEventListener('click', () => hideModal(modalId)));
  } else {
    console.error(`Modal with ID ${modalId} not found.`);
  }
}

function hideModal(modalId) {
  const modal = document.getElementById(modalId);
  if (modal) {
    modal.style.display = 'none';

    modal.removeEventListener('click', onOutsideClick);
    document.removeEventListener('keydown', onEscPress);
  } else {
    console.error(`Modal with ID ${modalId} not found.`);
  }
}

function onOutsideClick(event) {
  const modalContent = event.target.closest('.modal-content');
  if (!modalContent) {
    hideModal(event.currentTarget.id);
  }
}

function onEscPress(event) {
  if (event.key === 'Escape') {
    const openModal = document.querySelector('.modal[style*="flex"]');
    if (openModal) {
      hideModal(openModal.id);
    }
  }
}

function initSkipScreens() {
  const skipPhotoBtn = document.querySelector('[data-skip]');
  const skipTestBtn = document.querySelector('[data-test-known]')
  const doTestBtn = document.querySelector('[data-test-unknown]')
  const initTestQuestion = document.querySelector('[data-test-init]')

  if (skipPhotoBtn) {
    skipPhotoBtn.addEventListener('click', () => {
      showModal('warningModalPhoto');
    });
  } 
  
  if (skipTestBtn) {
    skipTestBtn.addEventListener('click', () => {
      showModal('warningModalTest');
    });
  }
  
  if (doTestBtn) {
    doTestBtn.addEventListener('click', () => {
      initTestQuestion.style.display = 'none'
      getTestData()
    })
  }
}

export { initSkipScreens, hideModal };
