import { BASE_URL, getData } from "../api";
import { getTestType } from "../user/sendTestType";

const testType = getTestType();

function reorderProducts(products) {
  return products.sort((a, b) => a.order - b.order);
}

async function getRecsData() {
  try {
    const response = await getData(`${BASE_URL}/core/test_result/?test_type=${testType}`);
    const shopAddress = response.data.shop_address || "Адрес не указан";
    let products = response.data.products_info || [];

    const productsArray = Object.values(products);
    products = reorderProducts(productsArray);

    const userPhoto = response.data.test_result || "{}";
    let testResultObject;
    
    try {
      testResultObject = JSON.parse(userPhoto.replace(/'/g, '"'));
    } catch (error) {
      console.error('Ошибка при парсинге данных test_result:', error);
      testResultObject = {}; 
    }

    const beforeFace = testResultObject.before_face || null;
    const afterFace = testResultObject.after_face || null;
    const brandsList = response.data.brands || [];

    return { shopAddress, products, beforeFace, afterFace, brandsList };
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
    return {}; 
  }
}

export { getRecsData };
