import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";
import { modalStack } from "../initModal";
import { getTestType } from "../user/sendTestType";
import { getRecsData } from './getRecsData';
import { Counter } from './manageRecsBasket';
import { getBrandsList } from "./recsFilter";

const morningCare = document.querySelector('[data-recs-morning]'); 
const eveningCare = document.querySelector('[data-recs-evening]');
const additionalCare = document.querySelector('[data-recs-addit]');

let originalProductCode = null;
let stage = null

const testType = getTestType()

class AlternativeProductCard {
  constructor(code, img, itemName, price, stage, parentElement, ...classes) {
    this.code = code;
    this.img = img;
    this.itemName = itemName;
    this.classes = classes;
    this.parent = parentElement;
    this.price = price;
    this.stage = stage;
  }

  render() {
    const card = document.createElement('li');
    card.classList.add('modal__alter-card');
    card.innerHTML = `
      <div class="card__prod" data-product-code=${this.code} data-stage=${this.stage}>
        <div class="card__prod-cont">
          <img src=${this.img} alt="Изображение продукта.">
          <div class="card__prod-text">
            <p class="card__prod-name">${this.itemName}</p>
            <span class="card__prod-price">${this.price} ₽</span>
          </div>
        </div>
        <button type="button" class="card__change btn btn--pink" data-recs-change>Заменить</button>
      </div>
    `;
    if (this.parent && typeof this.parent.append === 'function') {
      this.parent.append(card);
    } else {
      console.error('Parent element is not valid:', this.parent);
    }
  }
}

class ProductCard {
  constructor(name, description, code, img, sub_name, itemName, price, stage, parentElement, index, list_products, ...classes) {
    this.name = name;
    this.description = description;
    this.code = code;
    this.img = img;
    this.sub_name = sub_name;
    this.itemName = itemName;
    this.classes = classes;
    this.parent = parentElement;
    this.price = price;
    this.stage = stage;
    this.index = index;
    this.list_products = list_products;
  }

  render() {
    const card = document.createElement('li');
    card.classList.add('recs__card', 'card', 'swiper-slide');
    card.innerHTML = `
      <h3>Этап ${this.index + 1}: ${this.name}</h3>
      <p class="card__name">${this.description}</p>
      <div class="card__prod" data-recs-prod data-product-code=${this.code} data-stage=${this.stage}>
        <div class="card__prod-cont">
          <img src=${this.img} alt="Изображение продукта.">
          <div class="card__prod-text">
            <span class="card__prod-label">${this.sub_name}</span>
            <p class="card__prod-name">${this.itemName}</p>
            <span class="card__prod-price">${this.price} ₽</span>
          </div>
        </div>
        <button type="button" class="card__details js-modal" data-modal="details">Подробнее</button>
        <button type="button" class="card__alter btn js-modal" data-modal="alter">Альтернативное средство</button>
        <div class="card__counter" data-recs-counter>
          <button type="button" class="card__add btn btn--pink" data-recs-add>В корзину</button>
          <div class="card__counter-wrapper" data-recs-counter-cont>
            <button type="button" class="card__decr" data-recs-decr>
              <svg width="24" height="24" aria-hidden="true">
                <use xlink:href="#recs-minus"></use>
              </svg>
            </button>
            <input readonly type="number" class="card__input" data-recs-counter-input name="" value="0">
            <button type="button" class="card__incr" data-recs-incr>
              <svg width="24" height="24" aria-hidden="true">
                <use xlink:href="#recs-plus"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    `;
    if (this.parent && typeof this.parent.append === 'function') {
      this.parent.append(card);
    }

    const alterButton = card.querySelector('.card__alter');
    if (alterButton) {
      alterButton.addEventListener('click', () => {
        renderAlternativeCards(this.list_products.slice(1, 3));
      });
    }

    const replaceButton = card.querySelector('[data-recs-change]');
    if (replaceButton) {
      replaceButton.addEventListener('click', () => {
        const stage = card.querySelector('[data-stage]').dataset.stage;
        const productCode = card.querySelector('[data-product-code]').dataset.productCode;
        replaceProduct(stage, productCode);
      });
    }
  }
}

function renderAlternativeCards(alternativeProducts) {
  const alterList = document.querySelector('[data-alter-list]');
  alterList.innerHTML = '';

  alternativeProducts.forEach(product => {
    const { code, img, price, stage } = product;
    const alternativeProductCard = new AlternativeProductCard(
      code,
      img,
      product.name,
      price,
      stage,
      alterList
    );
    alternativeProductCard.render();
  });
}

function getParentElementByStage(stage) {
  switch (stage) {
    case 'Утренний уход':
      return morningCare;
    case 'Вечерний уход':
      return eveningCare;
    case 'Дополнительный уход':
      return additionalCare;
    default:
      return null;
  }
}

function getUserPhoto(beforeFace, afterFace) {
  const beforePhotoCont = document.querySelector('[data-recs-before]');
  const afterPhotoCont = document.querySelector('[data-recs-after]');

  if (!beforeFace || !afterFace) {
    return;
  }

  if (beforePhotoCont) {
    beforePhotoCont.src = 'data:image/png;base64,' + beforeFace;
  }

  if (afterPhotoCont) {
    afterPhotoCont.src = 'data:image/png;base64,' + afterFace;
  }
}

function renderSingleProductCard(product, index, renderedBrands, parentElement) {
  const { name, description, sub_name, list_products } = product;

  if (list_products && list_products.length > 0) {
    const firstProduct = list_products[0];
    if (firstProduct) {
      const { brand, code, img, name: itemName, price, stage } = firstProduct;
      renderedBrands.add(brand);

      if (parentElement) {
        const productCard = new ProductCard(
          name,
          description,
          code,
          img,
          sub_name,
          itemName,
          price,
          stage,
          parentElement,
          index,
          list_products
        );
        productCard.render();
      }
    }
  }
}


function renderProductCards(products) {
  const renderedBrands = new Set();
  console.log(products)

  const groupedProducts = {
    morningCare: [],
    eveningCare: [],
    additionalCare: []
  };

  Object.values(products).forEach(product => {
    const stageTypes = product.stage_type || [product.list_products[0].stage];
    
    stageTypes.forEach(stageType => {
      if (stageType === 'Утренний уход') {
        groupedProducts.morningCare.push(product);
      } else if (stageType === 'Вечерний уход') {
        groupedProducts.eveningCare.push(product);
      } else if (stageType === 'Дополнительный уход') {
        groupedProducts.additionalCare.push(product);
      }
    });
  });

  renderGroupedProducts(groupedProducts.morningCare, morningCare, renderedBrands);
  renderGroupedProducts(groupedProducts.eveningCare, eveningCare, renderedBrands);
  renderGroupedProducts(groupedProducts.additionalCare, additionalCare, renderedBrands);

  return renderedBrands;
}

function renderGroupedProducts(products, parentElement, renderedBrands) {
  products.forEach((product, index) => {
    renderSingleProductCard(product, index, renderedBrands, parentElement);
  });
}

async function replaceProduct(originalProductCode, alternativeCode, stage) {
  showLoadingOverlay("Замена продукта");
  stage = parseInt(stage, 10);
  const container = document.querySelector(`[data-product-code="${originalProductCode}"]`);

  const counter = new Counter(container);

  try {
    const response = await postData(`${BASE_URL}/core/test_result/?test_type=${testType}`, {
      'filter_type': 'replace',
      'data': {
        'stage': stage,
        'product_code': alternativeCode
      }
    });

    if (response.result) {
      await counter.deleteProdFromBasket(originalProductCode);
      location.reload()
    } else {
    }
  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

document.addEventListener('click', function (e) {
  const alterButton = e.target.closest('.card__alter');
  if (alterButton) {
    if (alterButton.dataset.listenerAttached !== 'true') {
      originalProductCode = alterButton.closest('[data-product-code]').getAttribute('data-product-code');
      stage = alterButton.closest('[data-stage]').getAttribute('data-stage');
      alterButton.dataset.listenerAttached = 'true';
    }
  }

  const changeButton = e.target.closest('.card__change');
  if (changeButton) {
    if (changeButton.dataset.listenerAttached !== 'true') {
      const alternativeProductCode = changeButton.closest('[data-product-code]').getAttribute('data-product-code');
      replaceProduct(originalProductCode, alternativeProductCode, stage);

      if (modalStack.current) {
        modalStack.current.close();
      }

      changeButton.dataset.listenerAttached = 'true';
    }
  }
});

async function renderRecsData() {
  showLoadingOverlay("Получаем данные по рекомендациям");

  try {

    const { shopAddress, products, beforeFace, afterFace, brandsList } = await getRecsData();
    // Фотографии
    getUserPhoto(beforeFace, afterFace);

    // Отрисовываем карточки товара и собираем бренды
    const renderedBrands = renderProductCards(products);

    // Фильтруем бренды
    const filteredBrandsList = brandsList.filter(brand => renderedBrands.has(brand));
    getBrandsList(filteredBrandsList);

    // Адрес
    if (shopAddress) {
      const shopAddressElement = document.querySelector('[data-shop-address]');
      shopAddressElement.textContent = shopAddress;
    }
  } catch (error) {
    console.error('Ошибка при рендеринге данных:', error);
  } finally {
    hideLoadingOverlay();
  }
}

export { renderRecsData }
