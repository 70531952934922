import "./utils/addSvgSprite.js";
import "./utils/polyfills.js";
import "./utils/loadingScreen.js";

import { openHeaderMenu } from "./modules/initBurger.js";
import { initModals } from "./modules/initModal.js";
import { initSideNav } from "./modules/initSideNav.js";
import { initPage } from "./modules/accessControl.js";
import { renderRecsData } from "./modules/recs/renderRecsData.js";
import { initPhotoUpload } from "./modules/photo/uploadPhoto.js";
import { getResults } from "./modules/result/getResults.js";
import { initSharing } from "./modules/result/shareResults.js";
import { initComparePhoto } from "./modules/recs/initComparePhoto.js";
import { showScreen } from "./modules/photo/initPhotoScreens.js";
import {
  initAdditSlider,
  initEveningSlider,
  initMorningSlider,
} from "./modules/recs/initRecsSliders.js";
import { updateTestElements } from "./modules/test/updateTestElements.js";
import { setProductDetails } from "./modules/recs/getProductDetails.js";
import { getUserToken } from "./modules/user/getUserToken.js";
import { checkBottomMenu } from "./utils/isBottomMenu.js";
import { clearLocalStorageExcept } from "./modules/api.js";
import { initSkipScreens } from "./utils/showSkipScreen.js";
import { handleInitialQuestion } from "./modules/test/manageTestData.js";

document.addEventListener("DOMContentLoaded", async () => {
  if (!window.initialized) {
    window.initialized = true;

    checkBottomMenu();
    initPage();
    handleInitialQuestion();
    openHeaderMenu();
    updateTestElements();
    initModals();
    showScreen(0);
    initSideNav();
    initPhotoUpload();
    initSkipScreens();
    initSharing();
    initComparePhoto();

    if (window.location.pathname.includes("index.html")) {
      clearLocalStorageExcept(["userToken", "access", "refresh", "userPhone"]);
    }
    if (window.location.pathname.includes("test-select.html")) {
      await getUserToken();
    } else if (window.location.pathname.includes("results.html")) {
      await getResults("usual", "");
    } else if (window.location.pathname.includes("recs.html")) {
      await renderRecsData();
      initMorningSlider();
      initEveningSlider();
      initAdditSlider();
      initModals();
      setProductDetails();
    }
  }
});
